var site = site || {};

(function ($, Drupal) {
  var productVideoBehavior = {
    setup: function ($modules, context) {
      // var animationTime = 1200;
      $modules.each(function () {
        init($(this));
      });
      function init($module) {
        var $play = $('.js-play', $module);
        var $landing = $('.js-video-landing', $module);
        var $reveal = $('.js-video-reveal', $module);
        var provider = $module.data('video-provider');
        var isYoutube = provider === 'youtube';
        var isZentrick = provider === 'zentrick';
        var isHTML5 = provider === 'html5';

        $module.off('animation.triggered').on('animation.triggered', function (event, enter) {
          if (enter) {
            $(document).trigger('videos.pause');
            // @TODO -- Update to be according to inputs in INC file
            if ($module.find('video').length) {
              $module.find('video').attr('muted', true);
            }
            if ($play.hasClass('js-play--autoplay')) {
              $play.trigger('click.play');
            }
          }
        });
        $play.off('click.play').on('click.play', function (event) {
          event.preventDefault();
          var bps = Unison.fetch.now().name === 'small';
          // Pass options with overlay / where to insert video content if not overlay
          var opts = {
            context: context,
            provider: provider,
            className: 'overlay--content-block-video overlay--content-block-video-product',
            openOverlay: $play.hasClass('js-play--overlay'),
            $content: $reveal,
            width: bps ? '100%' : '80%',
            height: bps ? '100%' : '60%',
            opacity: bps ? 0 : 1
          };

          // Triggered once video is loaded via JS and ready to show
          $reveal.off('video.loaded').on('video.loaded', function () {
            $landing.addClass('fading');
            // When this video starts playing, let the content block handle the active state
            if ($(this).closest('.js-content-block').length) {
              $(this).closest('.js-content-block').trigger('video.playing');
            }
            $reveal.addClass('active');
          });
          if (!!isYoutube) {
            opts.youTubeId = $module.data('youtube-id');
          } else if (!!isZentrick) {
            opts.zentrickId = $module.data('zentrick-id');
          } else if (!!isHTML5) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
          }
          site.videos.open(opts);

          return false;
        });
      }
    }
  };

  /**
   * We initialize each type of video separately because we don't know if/when their respective libraries are loaded
   */
  $(document).on('youtubeIframeAPI.loaded', function () {
    var $elements = $('.js-product-video').filter("[data-video-provider='youtube']");

    if ($elements.length) {
      productVideoBehavior.setup($elements, document);
    }
  });
  $(document).on('zentrick:loaded', function () {
    var $elements = $('.js-product-video').filter("[data-video-provider='zentrick']");

    if ($elements.length) {
      productVideoBehavior.setup($elements, document);
    }
  });
  Drupal.behaviors.videoBlockV1 = {
    attach: function (context) {
      var $elements = $('.js-product-video').filter("[data-video-provider='html5']");

      if ($elements.length) {
        productVideoBehavior.setup($elements, context);
      }
    }
  };
})(jQuery, Drupal);
